import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../auth/auth.service';
import { IDashboardInterfaces } from 'src/app/interfaces/interfaces';
import { SKIPLOADING } from '../../interceptor/httpi.interceptor';

@Injectable({
  providedIn: 'root',
})
export class KidService {
  kidInfo = new BehaviorSubject<IDashboardInterfaces.IKidInterfaces>(null);

  public kidID: BehaviorSubject<string> = new BehaviorSubject('');
  public kidActive = new BehaviorSubject(false);
  constructor(private http: HttpClient, private authservice: AuthService) {}

  getKids(index) {
    return this.http.get(
      environment.apiUrl + '/api/getAllKids/' + index
    ) as Observable<{ kids: IDashboardInterfaces.IkidsCard[]; tokens }>;
  }
  getSpecificKids(name) {
    return this.http.get(
      environment.apiUrl + '/api/getSpecificKids/' + name
    ) as Observable<{
      items: IDashboardInterfaces.IkidsCard[];
    }>;
  }
  addVisitCount(id: string): Observable<any> {
    return this.http.post(
      environment.apiUrl + '/api/addVisitCount/' + id,
      {},
      {
        context: new HttpContext().set(SKIPLOADING, true),
      }
    );
  }
  associateUser(data): Observable<any> {
    return this.http.post(environment.apiUrl + '/api/associateUser', data);
  }
  decryptKidToken(token: string): Observable<string> {
    return this.authservice.decodeTokenFromAPI(token).pipe(
      map((decoded) => {
        console.log(decoded);

        if (typeof decoded === 'string') {
          return decoded;
        }
        if (decoded.success === false) {
          return null;
        }
        return decoded.user_id;
      })
    );
  }
  updateKid(data): Observable<any> {
    return this.http.put(environment.apiUrl + '/api/updateKidState/', data);
  }
}
